export type ActCompletionEvent = {
  id: string;
  displayedName: string;
  inProgress?: boolean;
  evaluation?: 'GOOD' | 'BAD' | 'FAIL';
};

export type LoadFeedbacksEvent = {
  isLoading: boolean;
};

export type UserActionToastsEvent = {
  userActionToasts: { id: string; evaluation: 'GOOD' | 'BAD' | 'FAIL'; body: string }[];
};

export const GraphEventTypes = {
  ActCompletion: 'actCompletion',
  LoadFeedbacks: 'loadFeedbacks',
  UserActionToasts: 'userActionToasts'
};

export type GraphEvent = {
  type: keyof typeof GraphEventTypes;
  content: ActCompletionEvent | LoadFeedbacksEvent | UserActionToastsEvent;
};
