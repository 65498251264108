import { AbstractGraphObserver } from '@/AppClasses/ExerciseScenario/GraphNotifier/AbstractGraphObserver';
import {
  GraphEventTypes,
  GraphEvent
} from '@/AppClasses/ExerciseScenario/GraphNotifier/GraphEvent';
import {
  GraphObserverUpdateCallback,
  IGraphObserver
} from '@/AppClasses/ExerciseScenario/GraphNotifier/IGraphObserver';

class LoadFeedbacksObserver extends AbstractGraphObserver {
  constructor(name: string, updateCallback: GraphObserverUpdateCallback) {
    super(name, updateCallback);
  }

  update(event: GraphEvent): void {
    if (event.type !== GraphEventTypes.LoadFeedbacks) {
      return;
    }

    this.updateCallback(event);
  }
}

export function useLoadFeedbacksObserver(
  updateCallback: GraphObserverUpdateCallback
): IGraphObserver {
  return new LoadFeedbacksObserver('LoadFeedbacksObserver', updateCallback);
}
