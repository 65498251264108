import { GraphEvent } from './GraphEvent';
import { GraphObserverUpdateCallback, IGraphObserver } from './IGraphObserver';

export abstract class AbstractGraphObserver implements IGraphObserver {
  name: string;
  updateCallback: GraphObserverUpdateCallback;

  constructor(name: string, updateCallback: GraphObserverUpdateCallback) {
    this.name = name;
    this.updateCallback = updateCallback;
  }

  abstract update(event: GraphEvent): void;

  isActive(): boolean {
    return true;
  }
}
