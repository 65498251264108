import React from 'react';
import log from 'loglevel';
import { browserName, isSafari } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import ParticipantsModule from '@/AppClasses/Participants/ParticipantsModule';
import Menu from '@/components/Menu/Menu';
import PauseMenu from '@/components/PauseMenu/PauseMenu';
import DebugPanel from '@/components/Utilities/DebugPanel';
import { Translation } from '@/components/Utilities/Translation';
import MainRouting from '@/pages/MainRouting';
import '@/new-ui-index.scss';

export function withRouter(Children) {
  return (props) => {
    const navigate = useNavigate();
    return <Children {...props} navigate={navigate} />;
  };
}

class MainLayout extends React.Component {
  state = {
    width: 0,
    height: 0,
    exercisePlaying: false,
    exerciseIntro: false,
    expanded: null,
    logoutButton: true,
    isFullScreen: false
  };

  listeners = [];

  PauseTimeout_TimeBeforePopup = 900000; // (ms) 15 minutes
  PauseTimeout_TimeBeforeStop = 300000; // (ms) 5 minutes

  pausedDueToNoAudio = false;

  exercicePaused = false;

  // Constructor
  constructor(props) {
    super(props);

    // Participants module initialization or reset
    if (!ParticipantsModule.Instance) {
      new ParticipantsModule();
    } else {
      ParticipantsModule.Instance.Reset();
    }

    // React to event to set pause timeouts
    this.listeners.push(
      window.sdk.event().on('setPauseTimeouts', (timeBeforePopup, timeBeforeStop) => {
        this.setPauseTimeouts(timeBeforePopup, timeBeforeStop);
      })
    );
  }

  setPauseTimeouts = (timeBeforePopup, timeBeforeStop) => {
    this.PauseTimeout_TimeBeforePopup = timeBeforePopup;
    this.PauseTimeout_TimeBeforeStop = timeBeforeStop;
  };

  // Déconnecte l'user
  logout = () => {
    window.sdk.event().emit('modalOpen', {
      content: (
        <>
          <h2>
            <Translation keyName="general.confirmation">Confirmation</Translation>
          </h2>
          <p>
            <Translation keyName="mainPage.logout">
              Etes-vous sûr de vouloir vous déconnecter ?
            </Translation>
          </p>
          <div className="flex items-center justify-center space-x-6">
            <button
              className="cta cta--white"
              onClick={() => {
                window.sdk.event().emit('modalClose');
              }}>
              <Translation keyName="general.cancel">Annuler</Translation>
            </button>
            <button
              className="cta cta--accent"
              onClick={() => {
                window.sdk.user().logout();
                window.sdk.event().emit('modalClose');
              }}>
              <Translation keyName="general.logout">Déconnexion</Translation>
            </button>
          </div>
        </>
      )
    });
  };

  checkPause() {
    log.debug(
      'PAUSE',
      'checkPause before',
      this.pauseCountdown,
      this.pausedDueToNoAudio,
      this.finalPauseCountdown
    );

    if (!this.exercicePaused) return;

    if (this.pauseCountdown) return;

    if (this.pausedDueToNoAudio) return;

    if (this.finalPauseCountdown) return;

    log.debug('PAUSE', '==========================================> checkPause');

    this.intervalCheckPause = setInterval(() => {
      if (!this.exercicePaused) {
        log.debug('PAUSE', 'we are not in pause anymore, stop checking');
        clearInterval(this.intervalCheckPause);
        clearTimeout(this.pauseCountdown);
        this.pauseCountdown = null;
      }
    }, 1000);

    this.pauseCountdown = setTimeout(() => {
      clearInterval(this.intervalCheckPause);
      clearTimeout(this.pauseCountdown);
      log.debug('PAUSE', 'pauseCountdown');
      this.pauseCountdown = null;
      this.showPopinPause();
    }, this.PauseTimeout_TimeBeforePopup);
  }

  // ----------
  // Lors de l'initialisation
  // - Attache l'event lié au resize de la fenetre
  // - Modifie le state si un exercise a commencé ou s'est terminé
  componentDidMount() {
    if (window.location.pathname.startsWith('/feedback/')) {
      this.setState({
        feedback: true
      });
    }

    if (typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', this.onVisibilityChange);
    }

    this.listeners.push(
      window.sdk.event().on('enterExercise', () => {
        this.setState({
          exerciseIntro: true,
          expanded: false,
          exercisePause: false,
          feedback: false
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('startExercise', () => {
        this.setState({
          exercisePlaying: true,
          exerciseIntro: false,
          expanded: false,
          exercisePause: false,
          feedback: false
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('quitExercise', () => {
        this.setState({
          exerciseIntro: false,
          exercisePlaying: false
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('endExercise', () => {
        this.setState({
          exerciseIntro: false,
          exercisePlaying: false,
          feedback: true
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('pauseExercise', () => {
        this.setState({
          exercisePause: true,
          expanded: true,
          reasonPause: 'pause'
        });
        const event = new CustomEvent('toggleMenu', { detail: { isMenuExpanded: true } });
        document.dispatchEvent(event);
        this.checkPause();
      })
    );

    this.listeners.push(
      window.sdk.event().on('FeedbackPanelEntered', () => {
        this.setState({
          feedback: true
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('ExercisePanelEntered', () => {
        this.setState({
          logoutButton: false,
          feedback: false
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('fullscreenOpened', () => {
        // Log to DynamoDB
        window.sdk.usersActivity().createOne('Fullscreen', { State: 'Opened' });
        this.setState({
          isFullScreen: true
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('fullscreenExited', () => {
        // Log to DynamoDB
        window.sdk.usersActivity().createOne('Fullscreen', { State: 'Exited' });
        this.setState({
          isFullScreen: false
        });
      })
    );

    this.listeners.push(
      window.sdk.event().on('keydownDetected', () => {
        clearInterval(this.intervalCheckPause);
        clearTimeout(this.pauseCountdown);
        this.pauseCountdown = null;
        this.checkPause();
      })
    );

    // On device changed error protection for unplugged microphone
    this.listeners.push(
      window.sdk.event().on('onDeviceChanged', (kind) => {
        if (!this.state.exercisePlaying) return;
        log.debug('unplugged microphone, browser: ' + browserName);
        if (!window.testMode.skipDeviceDisconnectCheck && browserName !== 'Safari') {
          // Skip if in test mode or on Safari browser
          clearInterval(this.intervalCheckPause);
          clearTimeout(this.pauseCountdown);
          clearTimeout(this.finalPauseCountdown);
          this.pausedDueToNoAudio = true;
          this.setState({
            exercisePause: true,
            expanded: true,
            reasonPause: 'pause-due-device-changed',
            unpluggedDevice: kind
          });
          const event = new CustomEvent('toggleButtonsDisplay', {
            detail: { areButtonsHidden: true }
          });
          document.dispatchEvent(event);
          /* TODO: check where to restore buttons if necessary */

          this.checkPause();
          window.sdk.event().emit('exerciceDeconnected');
          window.sdk.event().emit('forcePause');
        }
      })
    );

    this.listeners.push(
      window.sdk.event().on('onSttFailedNoAudio', () => {
        window.sdk.event().emit('forcePause');
        this.pausedDueToNoAudio = true;
        let txt = (
          <Translation keyName="mainPage.mic_pb">
            Nous avons détecté un problème avec votre microphone. Merci de vous rapprocher du
            support client.
          </Translation>
        );
        this.setState({
          exercisePause: true,
          expanded: true,
          reasonPause: 'pausedDueToNoAudio',
          pauseTxt: txt
        });
        this.checkPause();
        this.showPopinPauseDueToNoAudio(txt);
      })
    );

    this.listeners.push(
      window.sdk.event().on('onSttFailedButAudio', () => {
        window.sdk.event().emit('forcePause');
        this.pausedDueToNoAudio = true;
        let txt = (
          <Translation keyName="mainPage.general_pb">
            Nous avons détecté un problème avec votre session. Merci de vous rapprocher du support
            client.
          </Translation>
        );
        this.setState({
          exercisePause: true,
          expanded: true,
          reasonPause: 'pausedDueToNoAudio',
          pauseTxt: txt
        });
        this.checkPause();
        this.showPopinPauseDueToNoAudio(txt);
      })
    );

    this.listeners.push(
      window.sdk.event().on('exercicePaused', () => {
        this.exercicePaused = true;
        this.setState({
          exercisePause: true,
          expanded: true
        });
        this.checkPause();
      })
    );

    this.listeners.push(
      window.sdk.event().on('exerciceResumed', () => {
        window.sdk.openFullscreen();
        this.setState({
          exercisePause: false,
          expanded: false
        });
        this.reInitPauseCheck();
      })
    );
    window.addEventListener('popstate', this.navBack);

    document.addEventListener('toggleMenu', this.toggleMenuFromEvent);
  }

  showPopinPauseDueToNoAudio(text) {
    window.sdk.event().emit('modalOpen', {
      isBackdropCloseButton: false,
      content: (
        <>
          <h2>
            <Translation keyName="mainPage.warning">Attention !</Translation>
          </h2>
          <p>{text}</p>
          <div className="flex items-center justify-center space-x-6">
            <button
              className="cta cta--white"
              onClick={() => {
                this.closePopinPauseDueToNoAudio();
              }}>
              <Translation keyName="general.back">Retour</Translation>
            </button>
            <button
              className="cta cta--accent"
              onClick={() => {
                this.closePopinPauseDueToNoAudio();
                this.showZendesk();
              }}>
              <Translation keyName="general.support">Contacter le support</Translation>
            </button>
          </div>
        </>
      )
    });
  }

  closePopinPauseDueToNoAudio() {
    window.sdk.event().emit('modalClose');
    this.pausedDueToNoAudio = false;
    this.setState({
      reasonPause: 'pausedDueToNoAudioAfter'
    });
  }

  openMenuFromPauseMenu = () => {
    this.setState({
      expanded: true,
      exercisePause: false,
      reasonPause: false
    });
  };

  toggleMenuFromEvent = (event) => {
    let { expanded, exercisePause } = this.state;
    if (event.detail.isMenuExpanded) {
      window.sdk.event().emit('pause', true);
    } else {
      exercisePause = false;
      window.sdk.event().emit('resume');
    }
    this.setState({
      expanded: !expanded,
      exercisePause,
      reasonPause: 'pause'
    });
  };

  navBack = () => {
    const url = window.location.pathname;
    if (url === '/') {
      this.setState({
        exerciseIntro: false,
        exercisePlaying: false
      });
    }
  };
  // ----------
  // Supprime les events
  componentWillUnmount() {
    window.removeEventListener('popstate', this.navBack);

    document.removeEventListener('toggleMenu', this.toggleMenuFromEvent);

    for (const i in this.listeners) {
      this.listeners[i]();
    }
  }

  onVisibilityChange = () => {
    if (isSafari) return;
    if (document.hidden && this.state.exercisePlaying && !this.exercicePaused) {
      window.sdk.event().emit('pause');
      this.setState({
        expanded: true,
        exercisePause: true,
        reasonPause: 'focus'
      });
      this.checkPause();
    }
  };

  resume = () => {
    this.setState({
      exercisePause: false,
      expanded: false
    });
    const event = new CustomEvent('toggleMenu', { detail: { isMenuExpanded: false } });
    document.dispatchEvent(event);
    window.sdk.event().emit('resume');
  };

  restart = (step = '') => {
    if (step === 'settings') window.localStorage.setItem('startStep', step);
    window.sdk.event().emit('restartExercise');
    this.setState({
      expanded: false,
      reasonPause: false
    });
    this.reInitPauseCheck();
  };

  stop = () => {
    this.setState({
      exercisePlaying: false,
      expanded: false,
      exercisePause: false,
      reasonPause: ''
    });

    if (!window.sdk.isInIframe()) {
      this.props.navigate('/');
    }

    window.sdk.event().emit('quitExercise');
    this.reInitPauseCheck();
  };

  reInitPauseCheck() {
    log.debug('PAUSE', 'reInitPauseCheck');
    clearInterval(this.intervalCheckPause);
    clearTimeout(this.pauseCountdown);
    clearTimeout(this.finalPauseCountdown);
    this.exercicePaused = false;
    this.pauseCountdown = null;
    this.pausedDueToNoAudio = null;
    this.finalPauseCountdown = null;
  }

  showPopinPause() {
    log.debug('PAUSE', 'showPopinPause');
    window.sdk.event().emit('modalOpen', {
      isBackdropCloseButton: false,
      content: (
        <>
          <h2>
            <Translation keyName="mainPage.still_there">Vous êtes toujours là ?</Translation>
          </h2>
          <p>
            <Translation keyName="mainPage.will_disconnect">
              Vous allez prochainement être déconnecté.e. Si vous souhaitez reprendre l'exercice,
              cliquez sur "Continuer".
            </Translation>
          </p>
          <button
            className="cta cta--accent"
            onClick={() => {
              window.sdk.event().emit('modalClose');
              clearTimeout(this.finalPauseCountdown);
              this.finalPauseCountdown = null;
              this.checkPause();
            }}>
            <Translation keyName="general.continue">Continuer</Translation>
          </button>
        </>
      )
    });

    this.finalPauseCountdown = setTimeout(() => {
      window.sdk.event().emit('exerciceDeconnected');
      this.setState({
        exercisePause: true,
        expanded: true,
        reasonPause: 'timeout-pause'
      });
      const event = new CustomEvent('toggleButtonsDisplay', { detail: { areButtonsHidden: true } });
      document.dispatchEvent(event);
      /* TODO: check where to restore buttons if necessary */

      this.checkPause();
      window.sdk.event().emit('modalClose');
    }, this.PauseTimeout_TimeBeforeStop);
  }

  showZendesk = () => {
    if (window.zE) {
      window.zE('webWidget', 'open');
      window.zE('webWidget', 'prefill', {
        name: {
          value: window.sdk.user().firstName + ' ' + window.sdk.user().lastName,
          readOnly: true // optional
        },
        email: {
          value: window.sdk.user().email,
          readOnly: true // optional
        }
      });
      window.zE('webWidget', 'show');
    } else {
      window.location = 'mailto:support@practicio.fr';
    }
  };

  // ----------
  // Affiche le rendu
  // - Teste si on est sur un petit ecran ou non, et modifie le rendu en fonction
  // - Change les boutons du sidebar par un bouton stop si on a commencé un exercise
  render() {
    return (
      <div className={this.state.exerciseIntro || this.state.exercisePlaying ? 'legacy' : ''}>
        <main>
          {!this.state.exercisePlaying && !this.state.exerciseIntro && !this.state.feedback && (
            <DebugPanel />
          )}

          {this.state.exercisePause ? (
            <PauseMenu
              reason={this.state.reasonPause}
              pauseTxt={this.state.pauseTxt}
              unpluggedDevice={this.state.unpluggedDevice}
              fancyFunctions={{
                resume: this.resume,
                stop: this.stop,
                menu: this.openMenuFromPauseMenu,
                restart: this.restart
              }}
            />
          ) : (
            this.state.expanded && (
              <Menu
                isExercisePlaying={this.state.exercisePlaying}
                isFeedBack={this.state.feedback}
                fancyFunctions={{
                  resume: this.resume,
                  stop: this.stop,
                  restart: this.restart,
                  showZendesk: this.showZendesk
                }}
              />
            )
          )}

          <MainRouting />
        </main>
      </div>
    );
  }
}

export default withRouter(MainLayout);
