import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useMemo } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { PasswordValidationIndicator } from '@/components/Utilities/PasswordValidationIndicator';
import { Translation } from '@/components/Utilities/Translation';
import { CognitoUserPool, CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';

const Register = () => {
  const getCognitoUserPool = useMemo(() => {
    const poolData = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
    };
    return new CognitoUserPool(poolData);
  }, []);

  const getCognitoUser = (email) => {
    return new CognitoUser({
      Username: email,
      Pool: getCognitoUserPool
    });
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect_url') || location.state?.redirect_url || '/';
  const [formData, setFormData] = useState({
    email: location.state?.email || '',
    password: location.state?.password || '',
    password2: location.state?.password || '',
    firstName: '',
    lastName: '',
    privacy: false,
    verificationCode: ''
  });
  const [failed, setFailed] = useState(false);
  const [info, setInfo] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [showVerification, setShowVerification] = useState(
    location.state?.unconfirmedUser || false
  );
  const [valided, setValided] = useState({
    number: false,
    special: false,
    length: false,
    min: false,
    maj: false,
    email: true,
    password: true,
    password2: true,
    privacy: true
  });

  useEffect(() => {
    if (location.state?.unconfirmedUser && formData.email) {
      const cognitoUser = getCognitoUser(formData.email);

      cognitoUser.resendConfirmationCode((err) => {
        if (err) {
          console.error('Error resending confirmation code:', err);
          setInfo({
            message: "Erreur lors de l'envoi du code de confirmation. Veuillez réessayer."
          });
        } else {
          setInfo({
            message: 'Un nouveau code de confirmation a été envoyé à votre adresse e-mail.'
          });
        }
      });
    }
  }, [location.state, formData.email]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const navigateToLogin = () => {
    navigate('/login', {
      state: { email: formData.email, redirect_url: window.sdk.getParam('redirect_url') }
    });
  };

  const submit = async () => {
    const { email, password, password2, firstName, lastName, privacy } = formData;
    if (!privacy || !valided.privacy) return false;
    if (!email || !valided.email) {
      setValided((prev) => ({ ...prev, email: false }));
      return false;
    }
    if (!password || !valided.number || !valided.special || !valided.min || !valided.maj) {
      setValided((prev) => ({ ...prev, password: false }));
      return false;
    }
    if (!password2 || password2 !== password) {
      setValided((prev) => ({ ...prev, password2: false }));
      return false;
    }
    if (!firstName || !lastName) {
      setFailed(true);
      setInfo({ message: 'Veuillez remplir tous les champs' });
      return false;
    }

    try {
      const attributeList = [
        new CognitoUserAttribute({ Name: 'email', Value: email }),
        new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
        new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
        new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
      ];

      getCognitoUserPool.signUp(email, password, attributeList, null, (err) => {
        if (err) {
          setFailed(true);
          setInfo({ message: err.message || JSON.stringify(err) });
          return;
        }
        // Successful signup
        setShowVerification(true);
        setInfo({
          message:
            "Inscription réussie. Veuillez entrer le code de vérification que vous avez reçu par e-mail. (Si vous n'avez pas reçu l'e-mail, vérifiez votre boîte de spam.)"
        });
      });
    } catch (e) {
      setFailed(true);
      setInfo({ message: "Erreur lors de l'inscription" });
    }
  };

  const verifyAccount = async () => {
    const { email, verificationCode, password } = formData;
    const cognitoUser = getCognitoUser(email);

    try {
      await new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      });

      setIsRegistered(true);

      await window.sdk.user().cognito.login(email, password);
      const registerResponse = await window.sdk
        .fetchInternalAPI()
        .post('/auth/register', { body: { email } });

      if (registerResponse.info.licenseStatus === 'invalid') {
        setFailed(true);
        setInfo({
          message: "Vous ne semblez pas avoir de licence pour accéder à l'application actuellement"
        });
        throw new Error('No valid license found');
      }

      const loginRes = await window.sdk.user().login({
        username: email,
        password: password
      });

      if (loginRes.state !== 'SUCCESS') {
        throw new Error('Login failed after registration');
      }
    } catch (err) {
      setFailed(true);
      if (err.message === 'No valid license found') {
        setInfo({
          message: "Vous ne semblez pas avoir de licence pour accéder à l'application actuellement"
        });
      } else {
        setInfo({ message: err.message || JSON.stringify(err) });
      }
    }
  };

  const updateField = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
    setFailed(false);

    if (key === 'email') {
      setValided((prev) => ({ ...prev, email: value.length === 0 ? true : validateEmail(value) }));
    }
    if (key === 'password') {
      setValided((prev) => ({
        ...prev,
        password: true,
        number: value.search(/.*\d/) >= 0,
        special: /[^a-zA-Z0-9]/.test(value),
        length: value.length >= 8,
        min: value.search(/.*[a-z]/) >= 0,
        maj: value.search(/.*[A-Z]/) >= 0
      }));
    }
    if (key === 'password2') {
      setValided((prev) => ({ ...prev, password2: value === formData.password }));
    }
  };

  const togglePassword = (field) => {
    if (field === 'Password') {
      setShowPassword((prev) => !prev);
    } else if (field === 'Password2') {
      setShowPassword2((prev) => !prev);
    }
  };

  if (isRegistered) {
    return (
      <>
        <h2 className="title">
          <Translation keyName="register.success">Inscription réussie</Translation>
        </h2>
        <Alert className="alert mt-4" variant="success">
          {info.message}
        </Alert>
        <button className="cta cta--accent mt-4" onClick={navigateToLogin}>
          <Translation keyName="register.returnToLogin">Retour au login</Translation>
        </button>
      </>
    );
  }

  if (showVerification) {
    return (
      <>
        <h2 className="title">
          <Translation keyName="register.verify">Vérifier votre compte</Translation>
        </h2>
        <Alert className="alert mt-4" variant="info">
          {info.message}
        </Alert>
        <Form.Group className="mt-4" controlId="verificationCode">
          <Form.Label className="pl-4 label">Code de vérification</Form.Label>
          <Form.Control
            type="text"
            name="verificationCode"
            placeholder="Entrez le code de vérification"
            onChange={(e) => updateField('verificationCode', e.target.value)}
            value={formData.verificationCode}
            required
            data-testid="verification-code-input"
          />
        </Form.Group>
        <button
          className="cta cta--accent mt-4"
          data-testid="verify-submit-btn"
          onClick={verifyAccount}>
          <Translation keyName="register.verify">Vérifier</Translation>
        </button>
      </>
    );
  }

  return (
    <>
      <h2 className="title">
        <Translation keyName="register.title">Créer un compte</Translation>
      </h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <Form.Group className="mt-4" controlId="email">
          <Form.Label className="pl-4 label">Email</Form.Label>
          {location.state?.isNewUser ? (
            <p className="mt-2 pl-4">{formData.email}</p>
          ) : (
            <Form.Control
              type="email"
              name="email"
              autoComplete="email"
              placeholder="Votre adresse email"
              onChange={(e) => updateField('email', e.target.value)}
              value={formData.email}
              required
              isInvalid={!valided.email}
              data-testid="register-email-input"
            />
          )}
        </Form.Group>

        <Form.Group className="mt-4" controlId="firstName">
          <Form.Label className="pl-4 label">Prénom</Form.Label>
          <Form.Control
            type="text"
            name="firstName"
            placeholder="Votre prénom"
            onChange={(e) => updateField('firstName', e.target.value)}
            value={formData.firstName}
            required
            data-testid="firstname-input"
          />
        </Form.Group>

        <Form.Group className="mt-4" controlId="lastName">
          <Form.Label className="pl-4 label">Nom</Form.Label>
          <Form.Control
            type="text"
            name="lastName"
            placeholder="Votre nom"
            onChange={(e) => updateField('lastName', e.target.value)}
            value={formData.lastName}
            required
            data-testid="lastname-input"
          />
        </Form.Group>

        <Form.Group className="mt-4" controlId="password">
          <Form.Label className="pl-4 label">Mot de passe</Form.Label>
          <div className="relative [&_.form-control]:pr-10">
            <Form.Control
              data-testid="register-password-input"
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Votre mot de passe"
              onChange={(e) => updateField('password', e.target.value)}
              value={formData.password}
              required
              isInvalid={!valided.password}
            />
            <button
              type="button"
              className="absolute right-4 top-1/2 -translate-y-1/2"
              onClick={() => togglePassword('Password')}>
              <FontAwesomeIcon icon={!showPassword ? faEye : faEyeSlash} />
            </button>
          </div>
          <PasswordValidationIndicator className="mt-3" valided={valided} />
        </Form.Group>

        <Form.Group className="mt-4" controlId="confirmPassword">
          <Form.Label className="pl-4 label">Confirmer votre mot de passe</Form.Label>
          <div className="relative [&_.form-control]:pr-10">
            <Form.Control
              type={showPassword2 ? 'text' : 'password'}
              name="password2"
              placeholder="Confirmez votre mot de passe"
              onChange={(e) => updateField('password2', e.target.value)}
              value={formData.password2}
              required
              isInvalid={!valided.password2}
              data-testid="confirm-password-input"
            />
            <button
              type="button"
              className="absolute right-4 top-1/2 -translate-y-1/2"
              onClick={() => togglePassword('Password2')}>
              <FontAwesomeIcon icon={!showPassword2 ? faEye : faEyeSlash} />
            </button>
          </div>
        </Form.Group>

        <Form.Group className="mt-4" controlId="privacy">
          <Form.Check type="checkbox">
            <Form.Check.Input
              type="checkbox"
              name="privacy"
              checked={formData.privacy}
              onChange={(e) => updateField('privacy', e.target.checked)}
              required
              isInvalid={!valided.privacy}
              data-testid="accept-tos-form"
            />
            <Form.Check.Label className="cursor-pointer transition-colors">
              J'ai lu et j'accepte{' '}
              <a
                href={window.policyUri}
                className="link text-current hover:text-current"
                target="_blank"
                rel="noreferrer">
                la politique de&nbsp;confidentialité
              </a>
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>

        {failed && (
          <Alert className="alert mt-4" data-testid="register-error-message" variant="danger">
            {info.message}
          </Alert>
        )}

        <button
          className="cta cta--accent mt-4"
          data-testid="register-submit-btn"
          disabled={false}
          type="submit"
          onClick={submit}>
          <Translation keyName="register.submit">S'inscrire</Translation>
        </button>

        <p className="mt-4">
          <LinkContainer
            to={{ pathname: '/', search: `redirect_url=${encodeURIComponent(redirectUrl)}` }}>
            <button className="link text-xs">
              <Translation keyName="register.login">Déjà inscrit ? Se connecter</Translation>
            </button>
          </LinkContainer>
        </p>
      </form>
    </>
  );
};

export default Register;
