import { AbstractGraphObserver } from '@/AppClasses/ExerciseScenario/GraphNotifier/AbstractGraphObserver';
import { GraphEventTypes, GraphEvent } from '@/AppClasses/ExerciseScenario/GraphNotifier/GraphEvent';
import {
  GraphObserverUpdateCallback,
  IGraphObserver
} from '@/AppClasses/ExerciseScenario/GraphNotifier/IGraphObserver';

class ActsCompletionObserver extends AbstractGraphObserver {
  constructor(name: string, updateCallback: GraphObserverUpdateCallback) {
    super(name, updateCallback);
  }

  update(event: GraphEvent): void {
    if (event.type !== GraphEventTypes.ActCompletion) {
      return;
    }

    this.updateCallback(event);
  }
}

export function useActsCompletionObserver(
  updateCallback: GraphObserverUpdateCallback
): IGraphObserver {
  return new ActsCompletionObserver('ActsCompletionObserver', updateCallback);
}
