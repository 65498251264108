import log from 'loglevel';
import Utils from '../../Utils/Utils';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import ParticipantsModule from '../../Participants/ParticipantsModule';

export default class BotVideo extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);
  TriggerTimeReached = new NodePort('TriggerTimeReached', 'output', this);

  // Parameters
  BotName = '';
  VideoName = '';
  PreventSpeakingFrame = false;
  TriggerTime = '';

  // Internal values
  Bot = null;
  Transcript = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.BotName = iProperties.BotName;
    this.VideoName = iProperties.Video;
    this.PreventSpeakingFrame = iProperties.PreventSpeakingFrame;
    this.TriggerTime = iProperties.TriggerTime;
  }

  async OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(this.GetIdentity() + "' has been activated by '" + iActivator.GetIdentity() + "'.");
    log.debug(
      this.GetIdentity() +
        " Bot '" +
        this.BotName +
        "' will play the video '" +
        this.VideoName +
        "'..."
    );

    // Get the bot if not already done
    if (!this.Bot) {
      this.Bot = ParticipantsModule.Instance.GetBot(this.BotName);
    }

    if (this.Bot) {
      // Display automatically the speaking frame if allowed
      if (!this.PreventSpeakingFrame) {
        this.Bot.setSpeakingState('speaking');
      }

      // Play the video
      this.Bot.setConnectionState('connected');
      this.Bot.PlayVideo(this.VideoName, {
        triggerTime: Utils.StringVideoTimeToFloatSeconds(this.TriggerTime),
        onTimeTriggered: () => {
          this.OnTriggerTimeReached();
        },
        onEnded: () => {
          this.OnActionFinished();
        }
      });

      // Get bot transcript from database
      await this.GetVideoTranscript();

      // Create auto subtitles if needed
      if (window.testMode.autoSubs) {
        this.CreateSubtitles();
      }

      // Log action to history
      this.LogToHistory();
    } else {
      log.debug("BotVideo: Bot '" + this.BotName + "' not found!");
    }
  }

  async GetVideoTranscript() {
    // Get bot video info from database if not already done
    if (!this.Transcript) {
      const videoInfos = await window.sdk.BotVideo().getOne(this.VideoName);
      //log.debug(this.GetIdentity() + '.GetVideoTranscript: video info = ', videoInfos);

      if (videoInfos.state === 'success') {
        this.Transcript = videoInfos.transcript;
      } else {
        log.debug(
          this.GetIdentity() +
            '.GetVideoTranscript: Error: transcript not found. Message: ' +
            (videoInfos.info?.message || 'No message available')
        );
      }
    } else {
      log.debug(
        this.GetIdentity() + '.GetVideoTranscript: transcript already loaded: ',
        this.Transcript
      );
    }
  }

  async CreateSubtitles() {
    let subtitles = this.Transcript;

    try {
      const gptAnswer = await window.sdk.fetchInternalAPI().post('/llm/auto-translate', {
        body: {
          language: window.testMode.autoSubsLanguage,
          textToTranslate: this.Transcript,
          exerciseSessionID: this.sdk.exerciseSessionID
        }
      });
      log.debug(this.GetIdentity() + '.CreateSubtitles: translation = ', gptAnswer.translation);

      subtitles = gptAnswer.translation;
    } catch (error) {
      log.error(this.GetIdentity() + `.CreateSubtitles: ${error}`);
    }

    this.Bot.SetSubtitles(subtitles);
  }

  async LogToHistory() {
    this.Graph.History.AddBotSpeech(this.ID, this.BotName, this.VideoName, this.Transcript);
  }

  Pause() {
    if (this.Bot && this.m_IsActive) this.Bot.Pause();
  }

  Resume() {
    if (this.Bot && this.m_IsActive && this.Graph.IsRunning()) this.Bot.Resume();
  }

  FreezeSystem() {
    if (this.Bot && this.m_IsActive) this.Bot.Pause();
  }

  UnfreezeSystem() {
    if (this.Bot && this.m_IsActive && this.Graph.IsRunning()) this.Bot.Resume();
  }

  Skip() {
    if (!this.m_IsActive) {
      return;
    }

    log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' skip.");

    // Hide automatically the speaking frame if allowed
    if (
      !this.PreventSpeakingFrame &&
      this.Bot &&
      !Utils.WillBotContinueTalking(this.Output, this.BotName)
    ) {
      this.Bot.setSpeakingState('no');
    }

    // Make the video bot stop current action and return to wait loop
    this.Bot.ReturnToWaitLoop();

    // Activate Outputs and stop the node
    this.SetActive(false);
    this.TriggerTimeReached.ActivateAllConnections();
    this.Output.ActivateAllConnections();
  }

  OnTriggerTimeReached() {
    if (!this.m_IsActive) {
      log.debug(
        this.GetIdentity() +
          " Bot '" +
          this.BotName +
          "' trigger time reached, but ignored since not active."
      );
      return;
    }

    log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' trigger time reached.");

    // Don't do anything else here since we want to avoid early speakingframe hiding or other actions

    this.TriggerTimeReached.ActivateAllConnections();
  }

  OnActionFinished() {
    if (!this.m_IsActive) {
      log.debug(
        this.GetIdentity() +
          " Bot '" +
          this.BotName +
          "' finished its video play action, but ignored since not active."
      );
      return;
    }

    log.debug(this.GetIdentity() + " Bot '" + this.BotName + "' finished its video play action.");

    // Hide automatically the speaking frame if allowed
    if (
      !this.PreventSpeakingFrame &&
      this.Bot &&
      !Utils.WillBotContinueTalking(this.Output, this.BotName)
    ) {
      this.Bot.setSpeakingState('no');
    }

    // Activate Output
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.ActivateAllConnections();
  }

  GetDetailedIdentity() {
    return super.GetDetailedIdentity() + ':' + this.BotName + ' - ' + this.VideoName;
  }

  PrintParameters() {
    //log.debug("BotName = " + this.BotName + ", VideoName = " + this.VideoName);
  }
}
