import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';
import { FEEDBACK_EVALUATIONS } from '../Solvers/DetailedFeedbacksSolver';
import { USER_ACTION_TAGS } from '../Solvers/constants';
import { GraphEventTypes } from '../GraphNotifier/GraphEvent';

export default class Act extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  NodeName = '';
  ActNumber = 0;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.NodeName = iProperties.NodeName;
    this.ActNumber = iProperties.ActNumber;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", iNodeName = " + this.NodeName + ".");
  }

  OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
    log.debug('TestCreation[Act] ', this.NodeName);

    this.Graph.SetCurrentActNode(this);
    this.Graph.Notifier.notify({
      type: GraphEventTypes.ActCompletion,
      content: {
        id: this.NodeName,
        displayedName: this.NodeName,
        inProgress: true
      }
    });

    this.ActivateOutput();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.ActivateAllConnections();
  }

  PrintParameters() {
    //log.debug("Act: NodeName = " + this.NodeName + ".");
  }

  OnActEvaluation() {
    const evaluation = this.SolveEvaluation();

    this.Graph.Notifier.notify({
      type: GraphEventTypes.ActCompletion,
      content: {
        id: this.NodeName,
        displayedName: this.NodeName,
        evaluation: evaluation,
        inProgress: false
      }
    });

    this.Graph.History.AddActCompletionEvent(this.ID, this.NodeName, evaluation);
  }

  SolveEvaluation() {
    const userActionsFeedbacksEvents = this.Graph.History.GetUserActionsFeedbacksByActName(
      this.NodeName
    );
    const userActionsFeedbacks = userActionsFeedbacksEvents.map((uafEvent) => {
      const uaf = this.Graph.GetFullUserActionFeedbackData(
        uafEvent.Content.UserActionFeedbackID,
        uafEvent.Content.NodeID
      );
      uaf.IsMissedOpportunity = uafEvent.Content.IsMissedOpportunity;
      return uaf;
    });

    const hasBadAction = userActionsFeedbacks.some((uaf) =>
      uaf.Tags.includes(USER_ACTION_TAGS.BAD_ACTION)
    );
    const hasGoodAction = userActionsFeedbacks.some(
      (uaf) => uaf.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION) && !uaf.IsMissedOpportunity
    );
    const hasLimitCaseAction = userActionsFeedbacks.some((uaf) =>
      uaf.Tags.includes(USER_ACTION_TAGS.LIMIT_CASE)
    );
    const hasMissedOpportunity = userActionsFeedbacks.some((uaf) => uaf.IsMissedOpportunity);

    if (hasLimitCaseAction) {
      return FEEDBACK_EVALUATIONS.FAIL;
    }

    if (hasMissedOpportunity) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    if (hasBadAction && !hasGoodAction) {
      return FEEDBACK_EVALUATIONS.BAD;
    }

    if (hasBadAction) {
      return userActionsFeedbacks.every((uaf) => uaf.Tags.includes(USER_ACTION_TAGS.BAD_ACTION))
        ? FEEDBACK_EVALUATIONS.FAIL
        : FEEDBACK_EVALUATIONS.BAD;
    }

    return FEEDBACK_EVALUATIONS.GOOD;
  }
}
