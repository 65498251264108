import log from 'loglevel';
import React, { createContext, useState, useEffect } from 'react';
import ExerciseGraphClass from '../../AppClasses/ExerciseScenario/ExerciseGraph';
import { useLoadFeedbacksObserver } from '../../hooks/useLoadFeedbacksObserver';
import { useActsCompletionObserver } from '../../hooks/useActsCompletionObserver';
import { useUserActionToastsObserver } from '../../hooks/useUserActionToastsObserver';
import { uniqBy } from '@/AppClasses/Utils/uniqBy';
import { HistoryEventTypes } from '../../AppClasses/ExerciseScenario/ExerciseSessionHistory';

export const ExerciseContext = createContext({});

export const ExerciseContextProvider = ({ children, idExercise, idExerciseSession }) => {
  const [jsonGraph, setJsonGraph] = useState({});
  const [ExerciseID, setExerciseID] = useState(idExercise ? idExercise : '');
  const [ExerciseSessionID, setExerciseSessionID] = useState(
    idExerciseSession ? idExerciseSession : ''
  );
  const [ExerciseGraph, setExerciseGraph] = useState('');
  const [step, setStep] = useState('');
  const [tryStart, setTryStart] = useState(0);
  const [cache, setCache] = useState({
    currentFile: 'waiting size informations',
    currentFileProgression: 'waiting size informations',
    totalProgression: 0
  });
  const [exerciseStarted, setExerciseStarted] = useState(false);
  const [ExerciseSession, setExerciseSession] = useState('');
  const [ExerciseSessionHistory, setExerciseSessionHistory] = useState('');
  const [RerunNodeID, setRerunNodeID] = useState(null);
  const [isFeedbacksLoading, setIsFeedbacksLoading] = useState(false);
  const [actsCompletion, setActsCompletion] = useState([]);
  const [userActionToasts, setUserActionToasts] = useState([]);

  useEffect(() => {}, [userActionToasts]);

  const onLoadFeedbacksUpdate = (event) => {
    setIsFeedbacksLoading(event.content.isLoading);
  };

  const onActCompletionUpdate = (event) => {
    setActsCompletion((prevActsCompletion) => {
      const updatedActsCompletion = [...prevActsCompletion];
      const index = updatedActsCompletion.findIndex((act) => act.id === event.content.id);
      if (index !== -1) {
        updatedActsCompletion[index] = event.content;
      }
      return updatedActsCompletion;
    });
  };

  const onUserActionToastsUpdate = (event) => {
    setUserActionToasts((prevUserActionToasts) => [
      ...prevUserActionToasts,
      ...event.content.userActionToasts
    ]);
  };

  const actsCompletionObserver = useActsCompletionObserver(onActCompletionUpdate);
  const loadFeedbacksObserver = useLoadFeedbacksObserver(onLoadFeedbacksUpdate);
  const userActionToastsObserver = useUserActionToastsObserver(onUserActionToastsUpdate);

  const LoadExerciseGraph = async () => {
    const graph = await window.sdk.exercise().downloadExerciseGraph(ExerciseID);
    let initialHistory = [];

    if (!graph) {
      return undefined;
    }

    if (RerunNodeID && ExerciseSessionID) {
      const exerciseSessionHistory = await window.sdk
        .exerciseSession()
        .getExerciseSessionHistory(ExerciseSessionID);

      setExerciseSessionHistory(exerciseSessionHistory);

      const historyAfterLastRewind = getHistoryAfterLastRewind(exerciseSessionHistory);
      initialHistory = getHistoryBefore(RerunNodeID, historyAfterLastRewind);
    }

    setJsonGraph(graph);
    instanciateGraph(graph, initialHistory);
    return graph;
  };

  const getHistoryAfterLastRewind = (history) => {
    let lastIndex = -1;
    for (let i = 0; i < history.length; i++) {
      if (history[i].EventType === 'Rewind') {
        lastIndex = i;
      }
    }

    return lastIndex !== -1 ? history.slice(lastIndex + 1) : history;
  };

  const getHistoryBefore = (rerunNodeID, history) => {
    let targetNodeIndex = history.findIndex(
      (event) => event.Content.NodeID === parseInt(rerunNodeID)
    );

    if (targetNodeIndex === -1) {
      return [];
    }

    return history.slice(0, targetNodeIndex);
  };

  const instanciateGraph = (graph, initialHistory = null) => {
    if (graph.state === 'fail') {
      return;
    }

    const graphObservers = [
      loadFeedbacksObserver,
      actsCompletionObserver,
      userActionToastsObserver
    ];

    const exerciseGraph =
      RerunNodeID && initialHistory
        ? new ExerciseGraphClass(
            graph,
            initialHistory,
            ExerciseSessionID,
            RerunNodeID,
            graphObservers
          )
        : new ExerciseGraphClass(graph, [], null, null, graphObservers);

    setExerciseGraph(exerciseGraph);
    setInitialActsCompletion(exerciseGraph, initialHistory);
    window.sdk.event().emit('ExerciseLoaded');
  };

  const setInitialActsCompletion = (exerciseGraphInstance, initialHistory) => {
    let initialActsCompletion = exerciseGraphInstance
      .GetNodesByType('Act')
      .sort((a, b) => a.ActNumber - b.ActNumber)
      .map((actNode) => ({
        id: actNode.NodeName,
        displayedName: actNode.NodeName,
        userActions: []
      }));

    if (!initialHistory || initialHistory.length === 0) {
      const uniqueActsCompletion = uniqBy(initialActsCompletion, 'id');
      uniqueActsCompletion[0].inProgress = true;

      setActsCompletion(uniqueActsCompletion);
      return;
    }

    const actCompletionEvents = initialHistory.filter(
      (event) => event.EventType === HistoryEventTypes.ACT_COMPLETION
    );

    initialActsCompletion = initialActsCompletion.map((act) => {
      const actCompletionEvent = actCompletionEvents.find(
        (event) => event.Content.ActName === act.id
      );

      return {
        ...act,
        evaluation: actCompletionEvent ? actCompletionEvent.Content.Evaluation : null
      };
    });

    const uniqueActsCompletion = uniqBy(initialActsCompletion, 'id');

    const firstUnevaluatedActIndex = uniqueActsCompletion.findIndex((act) => !act.evaluation);
    if (firstUnevaluatedActIndex !== -1) {
      uniqueActsCompletion[firstUnevaluatedActIndex].inProgress = true;
    }

    setActsCompletion(uniqueActsCompletion);
  };

  const setDebugInfo = (iID, iValue) => {
    window.sdk.event().emit('addDebugInfo', { iID, iValue });
  };

  const showHelpBtn = () => {
    if (window.zE) {
      window.zE('webWidget', 'prefill', {
        name: {
          value: window.sdk.user().firstName + ' ' + window.sdk.user().lastName,
          readOnly: true // optional
        },
        email: {
          value: window.sdk.user().email,
          readOnly: true // optional
        }
      });
      window.zE('webWidget', 'show');

      window.zE('webWidget:on', 'close', function () {
        window.zE('webWidget', 'show');
      });
    }
  };

  return (
    <ExerciseContext.Provider
      value={{
        jsonGraph,
        LoadExerciseGraph,
        ExerciseID,
        setExerciseID,
        ExerciseGraph,
        setDebugInfo,
        step,
        setStep,
        showHelpBtn,
        setTryStart,
        tryStart,
        cache,
        setCache,
        exerciseStarted,
        setExerciseStarted,
        ExerciseSessionID,
        setExerciseSessionID,
        ExerciseSession,
        setExerciseSession,
        ExerciseSessionHistory,
        setExerciseSessionHistory,
        RerunNodeID,
        setRerunNodeID,
        isFeedbacksLoading,
        actsCompletion,
        userActionToasts,
        setUserActionToasts
      }}>
      {children}
    </ExerciseContext.Provider>
  );
};
